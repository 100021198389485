// composables/useSagePay.ts
export const useSagePay = () => {
  useHead({
    script: [
      {
        src: process.env.NUXT_ENV === 'production'
          ? 'https://live.opayo.eu.elavon.com/api/v1/js/sagepay.js'
          : 'https://sandbox.opayo.eu.elavon.com/api/v1/js/sagepay.js',
        defer: true,
      }
    ]
  })
}
